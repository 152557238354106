@font-face {
  font-family: "FuturaHeavy";
  src: local("FuturaHeavy"), url("./fonts/futura/Futura-Heavy-font.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "FuturaLight";
  src: local("FuturaLight"), url("./fonts/futura/futura-light-bt.ttf");
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-family: "FuturaHeavy" !important;
  color: #8cac84;
}

p {
  font-family: "FuturaLight" !important;
}

div {
  font-family: "FuturaLight" !important;
}

.main-color {
  background-color: #6c5c54;
}
.secondary-color {
  background-color: #8cac84;
}

.tertiary-color {
  background-color: #f0f0f0;
}

@media only screen and (min-width: 992px) {
  .padding-special {
    padding: 6rem 20rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .width-special {
    width: 50% !important;
  }

  .height-special-img {
    height: 650px;
    object-fit: cover;
  }

  .height-special-p {
    height: 450px;
    padding: 130px 0;
    font-size: 120%;
  }

  .height-special-p2 {
    font-size: 120%;
  }

  .height-special-div {
    height: 450px;
    padding: 40px 0;
    font-size: 120%;
  }

  .width-special-rel-75 {
    width: 85% !important;
    position: relative;
    left: 40%;
  }
  .width-image {
    width: 560px !important;
    height: 1000px !important;
    object-fit: cover !important;
    object-position: 10% 10% !important;
  }
  .width-special-75 {
    width: 75% !important;
  }

  .bg-image-cropped {
    /* for small devices */
    /*background-image: url("../public/media/office.jpeg");*/
    /* lt ie8 */
    -ms-background-position-x: center;
    -ms-background-position-y: bottom;
    background-position: center;
    /* scale bg image proportionately */
    background-size: cover;
    /* ie8 workaround - http://louisremi.github.io/background-size-polyfill/ */
    /*-ms-behavior: url(/backgroundsize.min.htc);*/
    /* prevent scaling past src width (or not) */
    /* max-width: 1200px; */
    height: 100px;
  }

  .image-height {
    height: 100px !important;
    max-width: 100px;
  }
}

.containerCSS {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filter-image {
  filter: opacity(50%);
}

@media only screen and (max-width: 768px) {
  .menu-button {
    position: absolute;
    left: 10%;
  }
}
